import Link from "next/link";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Obfuscate from "react-obfuscate";
import { useInView } from "react-intersection-observer";
import { findPageIdByFilePath } from "modules/common/utils/findPageIdByFilePath";

import CallBackForm from "./CallBackForm";
import Modal from "../../modal/components/Modal";
import Icon from "../../common/components/Icon";
import useMenu, { COCKPIT_FOOTER_MENU_NAME } from "../hooks/useMenu";
import LocalizedLink from "./LocalizedLink";

export default function Footer() {
  const { formatMessage } = useIntl();
  const { menu } = useMenu({ name: COCKPIT_FOOTER_MENU_NAME });
  const [contactFormVisible, setContactFormVisible] = useState(false);
  const toggleContactForm = () => setContactFormVisible(!contactFormVisible);
  const [ref, inView] = useInView({ threshold: 0.5 });
  const addressPageId = findPageIdByFilePath("/lageplan");

  return (
    <>
      <div
        className={`position-relative footer-branding ${
          inView ? "animated" : ""
        }`}
        ref={ref}
      >
        <img
          className="position-absolute footer-branding__item zindex-1000"
          src="/static/img/hellgruen.svg"
          alt="publicare branding"
        />
        <img
          className="position-absolute footer-branding__item zindex-1020"
          src="/static/img/mittelgruen.svg"
          alt="publicare branding"
        />
        <img
          className="position-absolute footer-branding__item zindex-1030"
          src="/static/img/dunkelgruen.svg"
          alt="publicare branding"
        />
        <img
          className="position-absolute footer-branding__item zindex-1040"
          src="/static/img/blau.svg"
          alt="publicare branding"
        />
      </div>
      <footer className="color-bg-blue footer color-white">
        <Modal
          visible={contactFormVisible}
          onClose={() => setContactFormVisible(false)}
          disableOkButton
        >
          <CallBackForm visible onHide={() => setContactFormVisible(false)} />
        </Modal>
        <div className="container position-relative footer-newsletter-button">
          <div className="text-center pb-4 px-1">
            <Link
              prefetch={false}
              href={formatMessage({
                id: "newsletter_link",
                defaultMessage: "/de/newsletteranmeldung",
              })}
              className="button button--tertiary position-relative"
            >
              {formatMessage({
                id: "newsletter_button",
                defaultMessage: "Newsletter abonnieren",
              })}
            </Link>
          </div>

          <div className="row mt-3 px-4 font-size-small font-size-sm-normal">
            <div className="col-6 mb-5 col-md-3 " id="footer-address">
              <Icon
                icon="Standort"
                fill="currentColor"
                className="d-block mb-3 icon--xl"
              />
              <div className="white-space-pre-wrap">
                <FormattedMessage
                  id="publicare-address"
                  defaultMessage="Publicare AG\nVorderi Böde 9\n5452 Oberrohrdorf"
                  tagName="div"
                />
              </div>

              <LocalizedLink
                page={{ pageId: addressPageId }}
                className="d-block mt-4 link-hover color-green"
              >
                <>
                  <Icon
                    icon="Pfeil-rechts"
                    fill="currentColor"
                    className="mr-1 icon--medium"
                  />
                  {formatMessage({
                    id: "siteplan",
                    defaultMessage: "Lageplan",
                  })}
                </>
              </LocalizedLink>
            </div>
            <div className="col-6 mb-5 col-md-3" id="footer-phone">
              <Icon
                icon="Kundendienst"
                fill="currentColor"
                className="d-block mb-3 icon--xl"
              />
              <span>
                <div>
                  <Obfuscate
                    tel="+41564841500"
                    className="bold link-hover"
                    linkText={formatMessage({
                      id: "obfuscated",
                      defaultMessage: "Verschleiert",
                    })}
                  >
                    {formatMessage({
                      id: "publicare_phone_number",
                      defaultMessage: "+41 56 484 15 00",
                    })}
                  </Obfuscate>
                </div>

                <div>
                  {formatMessage({
                    id: "opening_times_days",
                    defaultMessage: "Mo - Fr",
                  })}
                </div>
                <div>
                  {formatMessage({
                    id: "opening_times_hours",
                    defaultMessage: "7 - 17 Uhr",
                  })}
                </div>
              </span>
              <button
                type="button"
                onClick={toggleContactForm}
                className="no-button link-hover d-block mt-4 text-center mx-auto color-green"
              >
                <Icon
                  icon="Pfeil-rechts"
                  fill="currentColor"
                  className="mr-1 icon--medium"
                />
                {formatMessage({
                  id: "callback_us",
                  defaultMessage: "Rückruftermin",
                })}
              </button>
            </div>
            <div className="col-6 mb-5 col-md-3">
              <Icon
                icon="Fax"
                fill="currentColor"
                className="d-block mb-3 icon--xl"
              />
              <div className="bold">
                {formatMessage({
                  id: "publicare_fax",
                  defaultMessage: "+41 56 484 15 11",
                })}{" "}
              </div>
            </div>
            <div className="col-6 mb-5 col-md-3">
              <Icon
                icon="Mail"
                fill="currentColor"
                className="d-block mb-3 w-100 icon--xl"
              />
              <Obfuscate
                href="mailto:info@publicare.ch"
                target="_blank"
                className="bold link-hover"
                rel="noopener noreferrer"
              >
                {formatMessage({
                  id: "publicare_info_email",
                  defaultMessage: "info@publicare.ch",
                })}
              </Obfuscate>
            </div>
          </div>
          <div className="px-3 pt-2 pb-4 text-center">
            <div className="container d-flex align-items-center justify-content-between px-2 flex-wrap">
              <div className="d-flex align-items-center justify-content-center w-100-for-mobile my-4">
                <a
                  href="https://www.instagram.com/publicare_ag"
                  className="mr-md-4 mx-2 ml-md-0 link-hover"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="instagram"
                >
                  <Icon
                    className="icon--large"
                    icon="social-instagram"
                    fill="#ffffff"
                  />
                </a>
                <a
                  href="https://www.facebook.com/publicareag"
                  className="mr-md-4 mx-2 ml-md-0 link-hover"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="facebook"
                >
                  <Icon
                    className="icon--large"
                    icon="social-media-facebook-1"
                    fill="#ffffff"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/publicare-ag"
                  className="mr-md-4 mx-2 ml-md-0 link-hover"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="linkedin"
                >
                  <Icon
                    className="icon--large"
                    icon="professional-network-linkedin"
                    fill="#ffffff"
                  />
                </a>
              </div>
              <nav className="nav nav--footer w-100-for-mobile my-4 font-size-small font-size-sm-normal">
                {(menu?.links || [])?.map(({ url, title }) => (
                  <LocalizedLink
                    key={url.route}
                    page={{ pageId: url?.route }}
                    className="link-hover mx-1 ml-md-4 ml-md-0"
                  >
                    {title}
                  </LocalizedLink>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
